<template>
  <v-app-bar
    app
    clipped-left
    color="background"
  >
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-toolbar-items>
          <v-icon
            v-if="$vuetify.breakpoint.xs && $route.name !== 'Onboarding.Overview'"
            id="close-btn"
            class='material-icons-round'
            @click="$emit('previous')"
          >
            mdi-chevron-left
          </v-icon>
          <h3 class="pageTitle">
            {{ $t(`views.Onboarding.${pageTitle}`) }}
          </h3>
        </v-toolbar-items>
        <v-spacer></v-spacer>
        <v-toolbar-items
          class="icon"
        >
          <v-icon
            id="close-btn"
            color="red"
            @click="$router.push({ name: 'Portfolio' })"
          >
            mdi-close
          </v-icon>
        </v-toolbar-items>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>

export default {
  name: 'ApplicationBar',
  props: {
    pageTitle: {
      type: String,
    },
  },
};
</script>

<style scoped>
.pageTitle {
  padding-left: 1em;
}
</style>
